@font-face {
    font-family: "Satoshi";
    font-display: block;
    src: local('Satoshi'), url('assets/fonts/Satoshi-Variable.woff2') format('woff2'),
        /* will be preloaded */
        url('assets/fonts/Satoshi-Variable.woff') format('woff'), url('assets/fonts/Satoshi-Variable.ttf') format('truetype'), url('assets/fonts/Satoshi-Variable.eot') format('embedded-opentype');
    /* src: url("assets/fonts/Satoshi-Variable.ttf") format("truetype"); */
}

* {
    font-family: 'Satoshi';
}

.m-none {
    margin: 0 !important;
}

.banner-stories {
    object-fit: cover;
    width: 100%;
    height: 50vh;
}

.banner-profile {
    object-fit: cover;
    width: 100%;
    height: 250px;
}

.centered-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.accordion-item {
    padding-bottom: 2em;
}

.trumbowyg-box,
.trumbowyg-editor {
    border-radius: .5rem;
}

.trumbowyg-button-pane {
    background: white !important;
}

.rsw-dd {
    border-radius: .2rem;
    background-color: #f5f5f5;
    padding: 0 1rem 0 1rem;
    font-size: medium;
}

.potw-slider{
    height: 18em !important;
    width: 100%;
    object-fit: cover;
}

.swiper-slide {
    max-height: 18em !important;
}

.swiper-wrapper .m-swiper-slide{
    flex-basis: fit-content !important;
}

.swiper-slide button img {
    width: 100%;
    height: 18em;
    border-radius: .5rem;
    object-fit: cover;
}

.swiper-slide iframe {
    height: 18em;
}

.swiper-button-next,
.swiper-button-prev {
    color: black !important;
    background: white;
    padding: 1rem !important;
    border-radius: 50%;
    width: 32px !important;
    height: 32px !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 2vh !important;
    font-weight: bolder;
}

.swiper-pagination-bullet-active {
    width: 15px !important;
    border-radius: 1rem !important;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    text-align: end;
    padding-right: 2rem;
    padding-bottom: 1rem;
}

.swiper-pagination-bullet {
    background: black !important;
}

.ds-swiper .swiper-pagination-bullet {
    background: #EA3323 !important;
}

.ds-swiper .swiper-horizontal>.swiper-pagination-bullets,
.ds-swiper .swiper-pagination-bullets.swiper-pagination-horizontal,
.ds-swiper .swiper-pagination-custom,
.ds-swiper .swiper-pagination-fraction {
    text-align: center;
    padding-right: unset;
    padding-bottom: unset;
}

.ds-swiper .swiper-horizontal>.swiper-pagination-bullets,
.ds-swiper .swiper-pagination-bullets.swiper-pagination-horizontal,
.ds-swiper .swiper-pagination-custom,
.ds-swiper .swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%;
}

.accordion-content p {
    margin: 0 !important;
}

.accordion button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding-top: 2em;
    font-size: 1.5rem;
    font-weight: bold;
    border: none;
    outline: none;
}

.accordion button:hover,
.accordion button:focus {
    cursor: pointer;
}

.accordion button:hover::after,
.accordion button:focus::after {
    cursor: pointer;
}

.accordion button .icon {
    display: inline-block;
    position: absolute;
    top: 50px;
    right: 0;
    width: 22px;
    height: 22px;
}

.accordion button .icon::before {
    display: block;
    position: absolute;
    content: '';
    top: 9px;
    left: 5px;
    width: 10px;
    height: 2px;
    background: currentColor;
}

.accordion button .icon::after {
    display: block;
    position: absolute;
    content: '';
    top: 5px;
    left: 9px;
    width: 2px;
    height: 10px;
    background: currentColor;
}

.accordion button[aria-expanded='true'] .icon::after {
    width: 0;
}

.accordion button[aria-expanded='true']+.accordion-content {
    opacity: 1;
    max-height: 9em;
    transition: all 200ms linear;
    will-change: opacity, max-height;
}

.accordion .accordion-content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
}

.accordion .accordion-content p {
    font-size: 1rem;
    font-weight: 300;
    margin: 2em 0;
}

.ava-profile {
    left: 0;
    top: 95%;
    transform: translate(0, -50%);
}

.edit-ava-profile {
    left: 40%;
    top: 85%;
    transform: translate(-50%, -50%);
}

.edit-banner-profile {
    right: 0;
    bottom: 0;
    transform: translate(-50%, -50%);
}

.truncate-wallet {
    overflow: hidden;
    width: 10vh;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.null-product {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.link-icon {
    width: 1.5vh;
}

.upvote-icon {
    width: 2.5vh;
}

.upvote-icon-home {
    width: 2.5vh;
}

.truncate-summary {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
    border-radius: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-color: rgb(228 228 231 / 1);
    width: 100%;
}

.categories-container {
    position: relative;
}

.close-modal-mobile {
    position: absolute;
    right: 0;
    top: 0;
}

.wrap-categories::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.categories-container:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white 85%);
    width: 3vh;
}

.categories-container .wrap-categories {
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    width: 8.5em;
}

.categories-container .category {
    display: inline-block;
    margin: 0px 30px 20px 0px;
}

.h-stories {
    height: 10em;
}

.ds-wrapper {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
}

.ds-close {
    top: 0;
    left: 0;
    padding: 1rem;
    position: absolute;
    z-index: 1500;
}

.ds-swiper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ds-swiper img {
    height: 70vh;
}

.ds-swiperslide {
    max-height: unset !important;
}

@media only screen and (max-width: 767.5px) {
    .swiper-home {
        width: 40vh !important;
    }

    .scroll-category {
        flex-wrap: wrap;
    }

    .link-icon {
        width: 2vh;
    }

    .upvote-icon-home {
        width: 2.5vh;
    }

    .upvote-icon {
        width: 3vh;
    }

    .ava-profile {
        left: 70%;
        top: 95%;
        transform: translate(-50%, -50%);
    }

    .banner-stories {
        height: unset;
    }

    .categories-container .wrap-categories {
        width: 10em;
    }

    .h-stories {
        height: 8em !important;
    }

    .swiper-slide {
        max-height: auto !important;
        height: auto !important;
    }

    .swiper-slide button img {
        width: 100%;
        height: 100%;
        border-radius: .5rem;
        object-fit: cover;
    }

    .swiper-slide iframe {
        height: 100%;
        width: 100%;
        border-radius: .5rem;
    }

    .ds-swiper img {
        height: auto !important;
    }
}

@media only screen and (max-width: 991.5px) {
    .swiper-home {
        width: 65vh;
    }

    .h-stories {
        height: 9em;
    }

    .reverse-box {
        justify-content: flex-end;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        overflow: hidden;
    }

    .categories-container .wrap-categories {
        width: 10em;
    }

    .ds-swiper img {
        height: auto !important;
    }
}